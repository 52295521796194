.home-section {
  background-color: #1c2237;
  padding: 160px 0;
  display: flex;
  justify-content: space-evenly;
}
.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
