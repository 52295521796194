@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Castoro&family=Domine&display=swap');

.home__hero-section {
  color: #fff;
  padding: 4rem 0;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: stretch;
}

.rowLight {
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  align-content: stretch;
  background: #252e48;
  padding: 5rem 0 4rem 0;
  flex-direction: row;
}

.leftCol {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.rightCol {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.rightColAnimationBox {
  height: 50rem;
  max-width: 50%;
}

.animateSideBox {
  max-width: 40%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 24px;
  font-size: 35px;
  line-height: 1.3;
  font-weight: 600;
  color: #f7f8fa;
  font-family: 'Domine', serif;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 1.65 !important;
}

.home__hero-img-wrapper {
  max-width: 555px;
  box-shadow: none;
  /* animation: bounce 3s ease infinite; */
}

.home__hero-img-wrapper-dark {
  max-width: 555px;
  animation: offbounce 5s ease infinite;
}

.home__hero-img {
  max-width: 100%;
  padding: 0 1rem;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@keyframes bounce {
  from,
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -10px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes offbounce {
  from,
  0% {
    transform: translate3d(0, -10px, 0);
  }

  50% {
    transform: translate3d(0, -0, 0);
  }

  100% {
    transform: translate3d(0, -10px, 0);
  }
}

@media screen and (max-width: 1150px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
    padding-left: 10px;
  }

  .leftCol,
  .rightCol {
    max-width: 100%;
    flex-basis: 100%;
    justify-content: center;
  }
}
