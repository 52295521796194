.home__rowContainer {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
}

.home__topContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170vh;
}

.home__mainTitlesContainer {
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 600;
  color: #f7f8fa;
  font-family: 'Domine', serif;
}

.home__mainTitle__top {
  font-size: 2.5rem;
  margin: 0 1rem;
  line-height: 1.3;
  font-weight: 600;
  text-align: center;
  color: #f7f8fa;
  font-family: 'Domine', serif;
}

.home__mainTitle__bottom {
  opacity: 0.8;
  margin: 1rem;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.65 !important;
}

.home__imageContainer {
  margin-top: 2rem;
}

.home__radqc-desktop {
  width: 64rem;
  opacity: 0.75;
}

@media screen and (min-width: 601px) and (max-width: 700px) {
  .home__topContent {
    height: 130vh;
  }
}

@media screen and (max-width: 600px) {
  .home__topContent {
    height: 100vh;
  }

  .home__mainTitle__top {
    font-size: 1.5rem;
  }
  .home__mainTitle__bottom {
    font-size: 1rem;
    margin: 1rem;
  }
}

@media screen and (min-width: 1600px) {
  .home__radqc-desktop {
    max-width: 1120px;
  }
}
